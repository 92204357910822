import React, { memo } from "react";
import logo from "../../../img/icon.svg";
import "./style.scss";
import CustomSubstring from "./CustomSubstring";
import { useState, useEffect } from "react";
import LoadingMessage from "../../layout/loading_message/Loading";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css"; // Importa os estilos do KaTeX
import UserIcon from "../../../img/user.png";

const Message = memo((props) => {
  const { qaList, textEffect, loading } = props;

  return (
    <div className="Chat">
      {qaList.map((qa, index) => (
        <div key={`question_${index}`}>
          {qa.role === "user" && (
            <div className="qs">
              <div className="txt">{qa.content}</div>
              <div className="icon">
                <img src={UserIcon} width="30" height="30" alt="Usuário" />
              </div>
            </div>
          )}

          {qa.role === "assistant" && (
            <div className="res">
              <div className="icon">
                <img src={logo} width="100" height="100" alt="Assistente" />
              </div>

              <div className="txt">
                {index === qaList.length - 1 && textEffect ? (
                  <CustomSubstring content={qa.content} delay={10} />
                ) : (
                  <ReactMarkdown
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeKatex]}
                    components={{ p: "span" }}
                  >
                    {qa.content}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          )}
        </div>
      ))}

      {loading[0] && (
        <div className="Chat">
          <div className="qs">
            <div className="txt">{loading[1]}</div>
            <div className="icon">
              <img src={UserIcon} width="30" height="30" alt="Usuário" />
            </div>
          </div>

          <div className="res">
            <div className="icon">
              <img src={logo} width="100" height="100" alt="Assistente" />
            </div>

            <div className="txt">
              <LoadingMessage />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Message;
