import React, { memo } from "react";
import logo from "../../../img/icon.svg";
import "./style.scss";
import axios from 'axios';
import CustomSubstring from './CustomSubstring';
import { useState, useEffect } from "react";
import LoadingMessage from "../../layout/loading_message/Loading";
import ReactMarkdown from 'react-markdown';
import UserIcon from "../../../img/user.png"


const Message = memo((props) => {
  const { qaList, chat_session_identifier, textEffect, loading, token, account_identifier } = props; 

  return (
    <div className="Chat">
      {qaList.map((qa, index) => (
        <div key={`question_${index}`}>
          {qa.role === "user" && (
            <div className="qs">
              <div className="txt">{qa.content}</div>
              <div className="icon">
                <img src={UserIcon} width="30" height="30" alt="Logo" />
              </div>
            </div>

            
          )}
  
          {qa.role === "assistant" && (
            <div className="res">
              <div className="icon">
                <img src={logo} width="100" height="100" alt="Logo" />
              </div>
  
              <div className="txt">
              {index === qaList.length - 1 && textEffect ? (

                <CustomSubstring content={qa.content} delay={10} />
                
              ) : (

                <ReactMarkdown components={{ p: 'span' }}> 
                  {qa.content}
                </ReactMarkdown>

              )}
           
              </div>
  
            </div>
          )}
        </div>
      ))}


      {loading[0] ? (
        <div className="Chat">
            <div className="qs">
              <div className="txt">{loading[1]}</div>
              <div className="icon">
                <img src={UserIcon} width="30" height="30" alt="Logo" />
              </div>
            </div>
       

            <div className="res">
                  <div className="icon">
                    <img src={logo} width="100" height="100" alt="Logo" />
                  </div>
      
                  <div className="txt">
                    <LoadingMessage></LoadingMessage>
                  </div>
            </div>

        </div>

        
       ) : null
      }

    </div>


  );
});

export default Message;
