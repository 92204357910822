function Suggestion(props) {
  
  return (
    <div className="card card-bg hover">
      <p className="currentColor">
        {props.content}
      </p>
    </div>
  );
}

export default Suggestion;