import React, { useState } from "react";
import SunIcon from "../../../assets/SunIcon";
import WarningIcon from "../../../assets/WarningIcon";
import ThunderIcon from "../../../assets/ThunderIcon";
import logo from "../../../img/icon.svg"
import "./style.scss";
import Suggestion from "../../layout/suggestion/Suggestion";
import Capacity from "../../layout/capacity/Capacity";

const Home = (props) => {

  const selectionSuggestion = (question) => {
    props.setPrompt(question);
  }

  const [account_identifier, setAccount_identifier] = useState(localStorage.getItem('accountIdentifier'));

  return (
    <div className="New">
      <div className="container-titulo">
      <div className="logoTitulo">
            <h1 className="logo">
              <img src={logo} width="140" height="80" alt="Logo" />
            </h1>
        </div>
        <div className="logoTitulo">
          <h1 className="title currentColor">Gepeto Chat</h1>
        </div>

        
      </div>

      <div className="flex">
        <div className="inner">
          <div className="card">

            <SunIcon />
            <h4 className="currentColor">Exemplos</h4>
          </div>

          {account_identifier === "UFSM" ? (
            <div>
              <Suggestion 
                content={`Quais foram os principais impactos gerados pela pandemia no setor de construção de sistemas de água e esgoto?`} 
                onClick={() => selectionSuggestion(`Quais foram os principais impactos gerados pela pandemia no setor de construção de sistemas de água e esgoto?`)} 
              />

              <Suggestion 
                content={`Como a tecnologia de reservatórios subterrâneos desenvolvida pode impactar o mercado de construção de sistemas de água e esgoto?`} 
                onClick={() => selectionSuggestion(`Como a tecnologia de reservatórios subterrâneos desenvolvida pode impactar o mercado de construção de sistemas de água e esgoto?`)} 
              />
            </div>
          ) : (
            <div>
              <Suggestion 
                content={"Sobre o que falam os pills que mencionam 'boicote'?"} 
                onClick={() => selectionSuggestion("Sobre o que falam os pills que mencionam 'boicote'?")} 
              />

              <Suggestion 
                content={"Sobre o que falam os últimos 2 Pills?"} 
                onClick={() => selectionSuggestion("Sobre o que falam os últimos 2 Pills?")} 
              />
            </div>
          )}

  
        </div>

        <div className="inner">
          <div className="card">

            <ThunderIcon />
            <h4 className="currentColor">Capacidades</h4>
          </div>

          {account_identifier === "UFSM" ? (

            <Capacity 
              content={`Utilize a IA do Gepeto para fazer perguntas sobre o Relatório Construção de Sistemas de Água e Esgoto`} 
            />

            ) : (

            <div className="card card-bg">
              <p className="currentColor">
                Faça perguntas sobre os últimos Pills retornados, além da base de posts que geraram os Pills.
              </p>
            </div>

          )}

        </div>

        {/* <div className="inner">
          <div className="card">

            <WarningIcon />
            <h4 className="currentColor">Limitações</h4>
          </div>

          <div className="card card-bg">
            <p className="currentColor">
              Somente 
            </p>
          </div>

          <div className="card card-bg">
            <p className="currentColor">
              O período máximo a ser trabalhado é de 30 dias.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
