import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'; // Importa os estilos de KaTeX

const CustomSubstring = ({ content, delay }) => {
  const [substring, setSubstring] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSubstring((prev) => {
        const currentLength = prev.length;
        if (currentLength >= content.length) {
          clearInterval(intervalId);
          return prev;
        }
        return prev + content.charAt(currentLength);
      });
    }, delay);

    return () => clearInterval(intervalId);
  }, [content, delay]);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{ p: 'span' }}
    >
      {substring}
    </ReactMarkdown>
  );
};

export default CustomSubstring;
