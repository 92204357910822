import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import axios from "axios";
import Menu from "../Menu/Menu";
import "./style.scss";
import LoadingInput from "../../layout/loading_input/Loading";
import Loading from "../../layout/loading_main/Loading";
import Home from "../Home/Home";
import SendIcon from "../../../assets/SendIcon";

function Main() {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [auxQuestion, setAuxQuestion] = useState('');
  const location = useLocation();

  // Dados em cache
  const [token, setToken] = useState(localStorage.getItem('authToken'));
  const [account_identifier, setAccount_identifier] = useState(localStorage.getItem('accountIdentifier'));

  // Envio inicial / buzz
  const sendQuestion = async (question) => {
    setError(false)
    setLoading(true);
    setAuxQuestion(question)
    setPrompt("");    
    const chat_session_identifier = uuid();    

    try {
      const response = await axios.post(
         process.env.REACT_APP_API_URL+"/buzz",

        {
          account_identifier: account_identifier,
          chat_session_identifier: chat_session_identifier,
          question: question,
        },
        {

          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
      );

      const lista = response.data.next_questions;
      const textEffect = true;
      setLoading(false);
      setPrompt("");
      setAuxQuestion("");      
      navigate(`/chat/${chat_session_identifier}`, { state: { lista, textEffect}}) ;

    } catch (error) {
      setLoading(false);
      setError(true)
      setPrompt("");
      console.error("Erro na requisição:", error);
    }

    
  };

  return (
    <div className="chat-container">
      <div className="menu-column">
        <Menu chatSessionTitle={'Novo chat'} />
      </div>

      <div className="content-column">
        <div className="main">
          <div className="contentArea">
            {loading ? 
            <Loading question={auxQuestion}/> 
            :
            <Home setPrompt={setPrompt}/>}
            
          </div>


          <div className="inputArea">
                {
                  error &&
                  <div className="error">
                    Algo deu errado, por favor, tente novamente.
                  </div> 
                }
            <div className="flexBody">
              <div className="box">
                <textarea
                  placeholder="Envie sua mensagem..."
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  disabled={loading}
                />

                {loading ? 

                  <button disabled> 
                    <LoadingInput /> 
                  </button>

                :

                  <button onClick={() =>{sendQuestion(prompt)}}>                
                    <SendIcon />
                  </button>

                  } 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
