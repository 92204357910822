function Capacity (props) {
  
  return (
    <div className="card card-bg">
      <p className="currentColor">
        {props.content}
      </p>
    </div>
  );
}

export default Capacity;